.footer {
    background-color: black;
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 1rem 1rem;
}


.social-media {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.signature {
    font-weight: normal;
    color: var(--text-secondary);
}

.footer-logo{
    height: 10rem;
    width: 20rem;
    margin-bottom: 2rem;
}

.footer .container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.social-media a{
    display: inline-block;
    text-align: center;
    background-color: var(--bg-secondary);
    width: 3rem;
    height: 3rem;
    color: white;
    border: solid 1px var(--text-secondary);
    border-radius: 50%;
}

.social-media i{
    font-weight: normal;
    font-size: 2rem;
    margin: 0;
    line-height: 3rem;
}

.social-media i:hover{
    color: var(--text-secondary);
}

.index{
    height: auto;
    background-color: transparent;
    width: 100%;
    padding: 2rem 0rem;
}

.index ul{
    display: flex;
    list-style-type: none;
    height: auto;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.index ul li{
    height: 3.5rem;
    border-right: 3px solid var(--bg-secondary);
    display: flex;
    align-items: center;
}

.index ul li a{
    display: flex;
    align-items: center;


    font-size: 1.5rem;
    color: var(--text-primary);
    font-weight: normal;
    
    text-decoration: none;
    margin: 0 1rem;
}

.index ul li:last-child{
    border: none;
}

.index li a:hover {
    color: var(--text-secondary);
}