.group-pic{
    width:100%;
    min-width: 900px;
}

.group-pic-container {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .centered {
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
  }

  .centered h3{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 5rem;
    font-weight: normal;
    color: var(--text-secondary);
    background-color: var(--bg-primary-faded);
  }

  .coach-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    border-bottom: 5px solid #7A1414;
  }

  .coach-container h1{
    color: white;
    font-size: 3rem;
    font-weight: bold;
  }

  .coach-cards{
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
    width:100%;
    flex-wrap: wrap;
  }
  .coach-card {
    background-color: var(--bg-primary-faded);
    display: flex;
    margin: 0 20px;
    margin-bottom: 100px;
    max-width: 700px;
  }
  
  .coach-pic {
    width: auto;
    height: auto;
    max-height: 400px;
  }
  
  .coach-text {
    background-color: transparent;
    padding: 0px 20px;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%; /* Change this to auto */
  }
  

  .coach-text h2{
    background-color: transparent;
    color: var(--text-secondary);
    color: white;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 5px;

  }
  .coach-text ul{
    padding-left: 20px;
  }
  .coach-text p{
    background-color: transparent;
    color: white;
    font-size: 1rem;
    font-weight: normal;
  }


  .athlete-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
  }

  .athlete-container h1{
    color: white;
    font-size: 3rem;
    font-weight: bold;
  }

  .athlete-cards{
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }


  .athlete-card{
    height: auto;
    background-color: var(--bg-primary-faded);
    max-width: 500px;
    display: flex;
    margin: 0 20px;
    margin-bottom: 100px;
  }

  .athlete-pic{
    width:auto;
    height: auto;
    max-width: 40%;
  }

  .athlete-text{
    background-color: transparent;
    padding: 20px  20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .athlete-text h2{
    background-color: transparent;
    color: var(--text-secondary);
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;

  }
  .athlete-text p{
    background-color: transparent;
    color: white;
    font-size: 1rem;
    font-weight: normal;
  }


  @media (max-width: 700px) {
    .centered h3{
        font-size: 2.5rem;
    }

    .coach-text h2{
      font-size: 1.5rem;
  
    }
    .coach-text p{
      font-size: 1rem;
    }

    .coach-card{
      flex-direction: column;
      align-items: center;
      padding-top: 5px;
    }

    .coach-text{
      height: auto;
    }

    .athlete-text h2{
      font-size: 1.5rem;
  
    }
    .athlete-text p{
      font-size: 1rem;
    }

    .athlete-card{
      flex-direction: column;
      align-items: center;
      padding-top: 5px;
    }

    .athlete-text{
      height: auto;
    }
}