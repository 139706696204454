.sponsor-background{
    width: 100vw;
    background-color: var(--bg-secondary-faded);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sponsor-background h2{

    font-family: Arial, Helvetica, sans-serif;
    font-size: 3rem;
    font-weight: normal;
    color: var(--text-secondary);
    font-weight: bold;
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    background-color: var(--bg-primary-faded);
    padding: 1rem;

}

.sponsor-pics {
    display: flex;
    width: 100vw;
    padding: 3rem 3rem;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.sponsor-pic{
    height: 10rem;
    width: 20rem;
    margin: 1rem 1rem;
}