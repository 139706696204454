.about {
    background-color: transparent;
    margin-top:30px;
    padding: 20px 0;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-text {

    padding: 40px 40px;
    color: var(--text-primary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2rem;
    text-align: center;
    width: 100%;
    font-weight: normal;
}


@media (max-width: 700px) {
    .about-text{
        font-size: 1rem;
        width: 100%;
    }
}