body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/black-red-camo.png");
  background-repeat: repeat;
  background-position: center;
  background-size: 1000px auto;
  background-color: black;
}
body, html, #root {
  margin: 0;
  padding: 0;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 }


 :root {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  --text-primary: white;
  --text-secondary: #fdb20c;
  --text-secondary-faded: rgba(253, 178, 12, 0.7);
  --bg-primary: black;
  --bg-primary-faded: rgba(29, 25, 25, 0.5);
  --bg-secondary: #880507;
  --bg-secondary-faded: rgba(136, 5, 7, 0.5);
}
