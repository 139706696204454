
.latest-news{
    margin-bottom: 100px;
}

.post{
    display: flex;
    margin-top: 50px;
    padding: 0px 30px;
    width: 100vw;
    justify-content: center;
}

.post-article{
    background-color: var(--bg-primary);
    margin-right: 20px;
    color: white;
    border: solid 3px var(--text-secondary);
    padding: 30px 30px;
    height: 300px;
    overflow: hidden;
    max-width: 1200px;
    min-width: 300px;
}

.post-results{
    justify-content: space-evenly;
    flex-direction: column;
    border: solid 3px var(--text-secondary);
    padding: 0px 30px;
    width: 35vw;
    display: none;
}

.post-article img{
    max-width: 100%;
    display: none;
}

.post-results.active{
    display:flex;
}

.post-article.active{
    height: auto;
}
.post-article.active img{
    display: inline-block;
}

.post-top{
    display: flex;
    width: 100%;
    justify-content:space-between;
    padding: 0px 0px;
    margin: 1rem 0px;
}

a{
    color: var(--text-secondary);
}


@media (max-width: 700px) {
    .post{
        flex-direction: column;
    }
    .post-results{

        width: 100%;
        flex-direction: row;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto; /* Set overflow-x to auto to enable horizontal scrolling */
    white-space: nowrap; /* Prevent line breaks within the container */ 
    scrollbar-width: thin; /* Set the width of the scrollbar */
    margin: 0 0;
    padding: 0 0;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .post-results::-webkit-scrollbar {
    width: 0.5rem; /* For browsers using Webkit (Chrome, Safari) */
  }
  
  .post-results::-webkit-scrollbar-thumb {
    background-color: var(--bg-secondary); /* Color of the scrollbar thumb */
  
  }
  
  .post-results::-webkit-scrollbar-track {
    background-color: var(--text-secondary); /* Color of the scrollbar track */
  }
  .post-article{
    margin: 0px;}

}

