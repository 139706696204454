.video-responsive {
    display: flex;
    /* flex-wrap: wrap; */
    /* justify-content: space-evenly; */
     height: auto;
     -webkit-overflow-scrolling: touch;
  overflow-x: auto; /* Set overflow-x to auto to enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks within the container */ 
  scrollbar-width: thin; /* Set the width of the scrollbar */
}

.video-responsive::-webkit-scrollbar {
  width: 0.5rem; /* For browsers using Webkit (Chrome, Safari) */
}

.video-responsive::-webkit-scrollbar-thumb {
  background-color: var(--bg-secondary); /* Color of the scrollbar thumb */

}

.video-responsive::-webkit-scrollbar-track {
  background-color: var(--text-secondary); /* Color of the scrollbar track */
}

.video-responsive iframe {
  flex: 0 0 auto; /* Prevent videos from shrinking to fit */
}

  