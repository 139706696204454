/* YouTubeVideoCarousel.css */

/* Style for custom dots */
.slick-dots {
    list-style: none; /* Remove default list styles */
    padding: 0; /* Remove default padding */
    text-align: center; /* Center the dots */
    background-color: transparent;
    height: 30px;
  }

.slick-dots li{
  height: 100%;
  padding: 10px 5px;
}
  
  .custom-dot {
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .custom-dot.active {
    background-color: #7A1414; /* Set your desired color for the active dot */
  }

  .custom-prev-arrow, .custom-next-arrow{
    background-color: transparent;
    position: absolute;
    /* top: 60%; */
    cursor: pointer;
  }

  .custom-next-arrow{
    right: 1rem;
  }
  
  .custom-prev-arrow{
    left: 1rem;
  }

  .fa-solid{
    color: white;
  }

  .youtube-video-container {
    display: flex;
    justify-content: center;
  }