header {
    height: auto;
    margin: 0px;
    border-bottom: 5px solid var(--bg-secondary);

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.logo{
    height: 4.5rem;
    cursor: pointer;
    margin-left: 1rem;
}

.navbar {
    height: auto;
    background-color: transparent;
}

.navbar ul{
    display: flex;
    list-style-type: none;
    height: auto;
    display: flex;
    align-items: center;
}

.navbar ul li{
    height: 3.5rem;
    border-right: 3px solid var(--bg-secondary);
    display: flex;
    align-items: center;
}

.navbar ul li a{
    display: flex;
    align-items: center;


    font-size: 2rem;
    color: var(--text-primary);
    font-weight: bold;
    
    text-decoration: none;
    margin: 0 1rem;
}

.navbar ul li:last-child{
    border: none;
}

.navbar li a:hover {
    color: var(--text-secondary);
}

.toggle-button {
    /* position: absolute;
     */
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 3rem;
    margin-right: 1rem;
    height: 2rem;
}

.toggle-button .bar {
    height: 5px;
    width: 100%;
    background-color: var(--bg-secondary);
    border-radius:10px;
}


@media (max-width: 700px) {
    .toggle-button{
        display: flex;
    }

    .navbar{
        display: none;
    }

    .navbar ul{
        width: 100vw;
        flex-direction: column;
    }

    .navbar ul li{
        border-right:none;
    }

    .navbar.active {
        display: flex;

    }

}