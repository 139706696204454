.velodrome-container {
  position: relative;
}

.velodrome-pic {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block;
}

.velodrome-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.velodrome-info {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  position: absolute;
  bottom: 0;
  right: 0;
}



.contact-container{
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 5rem 0;
}
  .contact{

    padding: 30px 30px;
    flex-direction: column;
    max-width: 60rem;
    background-color: var(--bg-primary-faded);;
    border: 3px solid var(--bg-secondary);
    margin: 0px 20px;
  }

  .contact h1{
    color: var(--text-secondary);
  }

  .contact p{

    padding-top: 30px;
    background-color: transparent;
    color: white;
  }


  @media (max-width: 700px) {
    .velodrome-content{
      position: relative;
      padding: 1rem 1rem;
    }
    .velodrome-info{
      position: relative;
    }
    .contact-container{
      padding: 0 0;
    }
  }

